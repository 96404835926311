@import '../../styles/ColorTheory.scss';

.wordsmyth_timer {
    width: 100%;
    height: 14px;
    padding: 3px;
    margin: 3px 0px;
    background: rgba(0,0,0,.4);
    border-radius: 10px;
    overflow: hidden;
    .timer {
        // @include color_theory;
        border-radius: 10px;
        transition: 1s linear;
        height: 100%;
        background: white;
    }
}