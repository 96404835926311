#writing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    #beginning {
        font-size: 20px;
        padding: 10px;
    }
    button {
        margin-top: 20px;
    }
}