@import "variables.scss";

#join {
    max-width: 600px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    #info_title {
        font-size: 30px !important;
    }
    .close_button {
        position: absolute;
        right: 25px;
        top: 25px;
        color: grey;
    }
    #title {
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        user-select: none;
        @media (max-width: 350px) {
            font-size: 16vw;
        }
        svg {
            margin-left: 10px;
            transition: .5s;
            cursor: pointer;
            &:hover {
                color: rgb(231, 55, 84);
            }
        }
    } 
    .nav {
        display: flex;
        justify-content: center;
        flex-wrap: 1;
        margin: 20px;
        margin-top: 0px;
        .navitem{ 
            padding: 20px;
            font-size: 25px;
            cursor: pointer;
            &.selected {
                background: $buttonColor;
                color: $buttonTextColor;
            }
            &:hover {
                text-decoration: underline;
            }  
        }
    }
    #form {
        * {
            box-sizing: border-box;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .wordsmyth_input {
            width:100%;
        }
        input, button {
            width: 100%;
        }
        button {
            align-self: center;
            margin-top: 15px;
        }
        #params {
            display: flex;
        }
        #gameid {
            // text-transform: uppercase;
        }
    }
}