@import './ColorTheory.scss';

.scores {
    margin-top: 20px;
    
    .score { 
        width: auto !important;
        min-width: 150px;
        @include color_theory;
        font-weight: 600;
        text-align: center;
        margin: 0px 0px 8px 0px;
        padding: 2px 5px;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
       
        .judge {
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            height: 100%;
            font-size: 20px;
            visibility: hidden;
            width: 20px !important;
            svg { 
                margin: auto;
            }
        }
    }
    
}