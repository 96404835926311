@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed&display=swap');
@import './ColorTheory.scss';
@import './variables.scss';

@use "sass:color";


:root {
    --red: 0;
    --green: 0;
    --blue: 0;
}


.quoteBlock {
    &.input {
        .box {
            &:disabled {
                background: rgb(212, 140, 140)
            }
            &:hover, :focus {
                background: $buttonColor;
                color: $buttonTextColor;
            }
        }
    }
    margin-left: -30px;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    .bonus_quote {
        flex-shrink: 0;
        display: flex;
        // justify-content: center;
        align-items: center;

        .bonus {
            &.hidden {
                visibility: hidden;
            }
            transform: scaleX(-1);
            svg {
                width: auto !important;
            }
            font-size: 30px;
        }
        .box {
            &.correct {
                @include color_theory;
            }
            margin: 0px;
            font-size: 18px;
            text-transform: none;

            padding: 10px;
            height: 0%;
            min-width: 100%;

            --red: 0;
            --green: 0;
            --blue: 0;
            border: rgb(var(--red), var(--green), var(--blue)) 3px solid;
            
            .quote {
                font-size: 20px;
                text-align: center;
                .origin {
                    font-style: italic;
                }
                .player {
                    display: flex;
                    justify-content: center;
                    font-style: italic;
                    padding-left: 25px;
                    .judge {
                        width: 20px !important;
                        margin: 2px 6px;
                        svg { 
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 800px) {
        // margin-left:
        display: flex;
        flex-direction: column;
        justify-content: center;
        .votes {
            flex-direction: row !important;
            justify-content: center;
            align-items: center;
            width:100%;
            margin-left: 30px !important;
            
            .vote {
                max-width: 100px;
                margin:4px !important;
            }
        }
    }
    .votes {
        @media only screen and (min-width: 800px) {
            max-width: 100px;
        }
        margin: 0px 5px;
        margin-left: 35px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .vote {
            @include color_theory;
            font-weight: 600;
            padding: 4px 5px;
            border-radius: 10px;
            font-family: 'Roboto', sans-serif;
            text-align: center;
            position: relative;
            margin: 1px 1px 1px 10px;

            @media only screen and (min-width: 800px) {
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 8px 9px 0;
                    --border-alpha: calc((var(--perceived-lightness) - var(--border-threshold)) * -100);
                    border-color: transparent rgba(var(--red), var(--green), var(--blue), var(--border-alpha)) transparent transparent;
                    position: absolute;
                    left: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 8px 9px 0;
                    border-color: transparent rgba(calc(var(--red) - 50), calc(var(--green) - 50), calc(var(--blue) - 50), var(--border-alpha)) transparent transparent;
                    position: absolute;
                    left: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
}