@mixin color_theory {

    /*the threshold at which colors are considered "light". 
    Range: decimals from 0 to 1,
    recommended 0.5 - 0.6*/
        --threshold: 0.5;
    /*the threshold at which a darker border will be applied.
    Range: decimals from 0 to 1,
    recommended 0.8+*/
        --border-threshold: 0.8;
    /*sets the background for the base class*/
        background: rgb(var(--red), var(--green), var(--blue)) !important;
    
        /* 
    Calcs perceived brightness using the 
    sRGB Luma method
    lightness = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255
    */
        --r: calc(var(--red) * 0.2126);
        --g: calc(var(--green) * 0.7152);
        --b: calc(var(--blue) * 0.0722);
        --sum: calc(var(--r) + var(--g) + var(--b));
        
        --perceived-lightness: calc(var(--sum) / 255);
        
    /*
    1) Any lightness value above the threshold will be considered "light", therefore apply a black text color. Any bellow will be considered dark, and use white color. 
    This results from appying either a sub-zero (negative) or a higher-than-100 lightness value, which are capped to 0 and 100 respectively, to a HSL declaration
    */
        color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)) !important;
      
    /*
    2) sets the border as a 50% darker shade of the base color, ONLY if background color luma is higher than the border threshold.
    To achieve this I use the same sub-zero or higher-than-max technique, only this time using the Alpha value from an RGBA declaration. 
    This results in a border that's either fully transparent or fully opaque
    */
        --border-alpha: calc((var(--perceived-lightness) - var(--border-threshold)) * 100) !important;
        
        border-width: .2em;
        border-style: solid;
        border-color: rgba(calc(var(--red) - 50), calc(var(--green) - 50), calc(var(--blue) - 50), var(--border-alpha)) !important;
    }