@import './ColorTheory.scss';

#game {
    width: 100%;
    max-width:600px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    transition: .5s;
    align-items: center;
    justify-content: space-between;
    * {
        width: 100%;
    }
    #header {
        // display: flex;
        border-radius: 5px;
        padding: 3px 10px;
        margin-bottom: 20px;
        @include color_theory;
        #top {
            display: flex;
            #title {
                font-size: 200%;
                justify-content: center;
                align-self: center;
                user-select: none;
            }
            #timer {
                font-size: 200%;
                align-self: center;
                text-align: center;
    
            }
            #info {
                width: auto !important;
                align-self: flex-end;
                text-align: right;
                #username {
                    display: flex;
                    flex-direction: row-reverse;
                    font-size: 100%;//18px;
                    .judge {
                        // display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px !important;
                        margin: 2px 4px;
                        svg { 
                            margin: auto;
                        }
                    }
                }
                #gameid {
                    font-size: 200%;
                    // text-align: right;
                } 
            }
            @media (max-width: 350px) {
                flex-direction: column;
                align-items: center;
                #title {
                    text-align: center;
                }
                #info {
                    align-self: center;
                    text-align: center;
                    #username {
                        justify-content: center;
                    }
                }
            }
        }
    }
    #helperText {
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 22px;
    }
    #waiting {
        padding: 20px 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        button {
            margin-top: 20px;
        }
    }
    #bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        #leave_game {
            max-width: 100px;
            margin: 3px;
            padding: 5px 10px;
            font-size: 12px;
        }
    }
    #winner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        .player {
            @include color_theory;
            margin-right: 5px;
            max-width: 100px;
            font-weight: 600;
            padding: 4px 5px;
            border-radius: 10px;
            font-family: 'Roboto', sans-serif;
            text-align: center;
        }
    }
    #quoteorigin {
        font-size: 25px;
        align-self: flex-start;
        margin-bottom: 10px;
    }
    #emptyquotes {
        font-size: 25px;
        align-self: flex-start;
        text-align: center;
        margin: 20px 0px;
    }
}