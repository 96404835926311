@import './variables.scss';
@import './ColorTheory.scss';

.App {
    overflow: scroll;
    padding: 25px;
    height: 100vh;
    // padding-bottom: 100px;
 
    align-items: center;
    font-family: 'Roboto Condensed', sans-serif;
    box-sizing: border-box;
    #home {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    button {
        // @include color_theory;
        font-family: 'Roboto Condensed', sans-serif;
        border: 2px black solid;
        background: white;
        &:hover{
            background: $buttonColor;
            color: $buttonTextColor;
        }
        &:disabled {
            cursor: default;
            background: rgb(248, 248, 248) !important;
            color: black !important;
        }
        max-width: 250px;
        margin: 3px;
        padding: 5px 10px;
        font-size: 18px;
        cursor: pointer;
        text-transform: capitalize;
    }
    .spacer {
        padding: 2px;
    }
    *{
        box-sizing: border-box;
    }
}